import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const MentionsLegalesPage = () => (
  <Layout pathEn="/en/">
    <SEO
      title="Mentions légales"
      description="Dr Roure Philippe a ouvert ce site pour l'information personnelle de ses utilisateurs sur sa pratique de chirurgien orthopédiste spécialiste de la chirurgie du bras."
    />
    <Wrapper>
      <h1>Mentions légales</h1>
      <h2>Propriété du site</h2>
      <p>
        <strong>Dr Philippe Roure</strong>
        <br />
        Espace Medical Vauban 2A
        <br />
        Avenue de Ségur
        <br />
        75007 Paris
      </p>
      <h2>Hébergement du site</h2>
      <p>
        <a
          href="https://www.netlify.com/"
          target="blank"
          rel="noreferrer noopener"
        >
          Netlify
        </a>
        <br />
        2325 3rd St.
        <br />
        San Francisco, CA 94107
        <br />
        États-Unis
      </p>
      <h2>Droit et copyright</h2>
      <p>
        Dr Roure&nbsp;a ouvert ce site pour l'information personnelle de ses
        utilisateurs. Aucune exploitation commerciale m&ecirc;me partielle des
        donn&eacute;es qui y sont pr&eacute;sent&eacute;es ne pourra &ecirc;tre
        effectu&eacute;e sans l'accord pr&eacute;alable et &eacute;crit
        de&nbsp;Dr Roure
      </p>
      <p>
        La pr&eacute;sentation et le contenu du pr&eacute;sent site constituent
        ensemble une &oelig;uvre prot&eacute;g&eacute;e par les lois en vigueur
        sur la propri&eacute;t&eacute; intellectuelle. Aucune reproduction et/ou
        repr&eacute;sentation, partielle ou int&eacute;grale, ne pourra
        &ecirc;tre faite sans l'accord pr&eacute;alable et &eacute;crit
        de&nbsp;Dr Roure.
      </p>
      <p>
        Les dessins, photographies, images, textes, s&eacute;quences
        anim&eacute;es sonores ou non, et autres documentations
        repr&eacute;sent&eacute;es sur le pr&eacute;sent site Internet sont
        objets de droits de propri&eacute;t&eacute; industrielle et/ou
        intellectuelle, propri&eacute;t&eacute;s de Dr Roure. A ce titre, toute
        reproduction, repr&eacute;sentation, adaptation, traduction, et/ou
        transformation, partielle ou int&eacute;grale, ou transfert sur un autre
        site sont interdits.
      </p>
      <p>
        La copie &agrave; usage priv&eacute; de ces diff&eacute;rents objets de
        droits est autoris&eacute;e moyennant mention de la source. Leur
        reproduction partielle ou int&eacute;grale, sans l'accord
        pr&eacute;alable et &eacute;crit de Dr Roure, est strictement interdite.
      </p>
      <p>
        Tout lien ou framing du site est strictement interdit et en
        cons&eacute;quence, aucun &eacute;l&eacute;ment figurant sur une page du
        site Internet de Dr Roure&nbsp;ne peut &ecirc;tre partiellement ou
        totalement diffus&eacute;, transf&eacute;r&eacute; ou
        ins&eacute;r&eacute; sur un autre site Internet, ni servir &agrave;
        cr&eacute;er des produits d&eacute;riv&eacute;s, sauf autorisation
        expresse de Dr Roure.
      </p>
      <p>
        Les renseignements et pr&eacute;sentations contenus dans les pages de ce
        site sont fournis &agrave; titre purement indicatif et restent
        susceptibles de modifications &agrave; tout moment et sans
        pr&eacute;avis. Leur utilisation &agrave; des fins priv&eacute;es ou
        professionnelles ne saurait engager la responsabilit&eacute; de Dr
        Roure&nbsp;de quelque mani&egrave;re que ce soit.
      </p>
      <h2>Responsabilités</h2>
      <p>
        Dr Roure&nbsp;ne peut garantir l'exactitude, la pr&eacute;cision ou
        l'exhaustivit&eacute; des informations mises &agrave; disposition sur ce
        site. En cons&eacute;quence, Dr Roure&nbsp;d&eacute;cline toute
        responsabilit&eacute; :
      </p>
      <ul>
        <li>
          pour toute impr&eacute;cision, inexactitude ou omission portant sur
          des informations disponibles sur ce site
        </li>
        <li>
          pour tous dommages, directs et/ou indirects, quelles qu'en soient les
          causes, origines, natures ou cons&eacute;quences, provoqu&eacute;es
          &agrave; raison de l'acc&egrave;s de quiconque au site ou de
          l'impossibilit&eacute; d'y acc&eacute;der, de m&ecirc;me que
          l'utilisation du site et/ou du cr&eacute;dit accord&eacute; &agrave;
          une quelconque information provenant directement ou indirectement du
          site
        </li>
        <li>
          pour tout usage qui pourra &ecirc;tre fait de ces diff&eacute;rentes
          informations
        </li>
      </ul>
      <p>
        Aucune consultation m&eacute;dicale ne pourra vous &ecirc;tre
        donn&eacute;e par t&eacute;l&eacute;phone ou e-mail.
      </p>
      <p>
        Un rendez-vous vous sera en revanche propos&eacute;, si vous le
        souhaitez, dans les meilleurs d&eacute;lais avec le docteur Philippe
        Roure.
      </p>
      <h2>Confidentialité</h2>
      <p>
        Les dossiers de patients du Docteur Roure ou les renseignements
        personnels et médicaux les concernant ne sont pas exploités dans ce
        site.
      </p>
      <p>
        Le traitement des informations confidentielles telles que les numéros de
        téléphone, les adresses électroniques ainsi que les échanges par e-mail
        entre les visiteurs et le webmaster ne sont pas enregistrés dans une
        base de données du site.
      </p>
      <p>
        Le site utilise le service{" "}
        <a
          href="https://formspree.io/"
          target="blank"
          rel="noreferrer noopener"
        >
          Formspree
        </a>{" "}
        pour traiter les messages envoyés avec le formulaire de contact. Les
        informations renseignées dans le formulaire sont envoyées directement
        sur l'adresse mail du secrétariat du cabinet médical et ne sont pas
        enregistrées au niveau du site internet ou des serveurs d’hébergement.
        Si vous ne souhaitez pas utiliser le service Formspree, vous pouvez
        contacter directement le secrétariat par téléphone (01 53 59 88 09).
      </p>
      <p>
        Le site prévoit d’appliquer les obligations légales de confidentialité
        en matière d’informations médicales ou concernant la santé en France.
      </p>
      <h2>Cookies</h2>
      <p>
        Les cookies sont des données stockées dans l’équipement terminal d’un
        internaute et utilisées par le site pour envoyer des informations au
        navigateur de l’internaute, et permettant à ce navigateur de renvoyer
        des informations au site d’origine (par exemple un identifiant de
        session, le choix d’une langue ou une date).
      </p>
      <p>
        Les cookies permettent de conserver, pendant la durée de validité du
        cookie concerné, des informations d’état lorsqu’un navigateur accède aux
        différentes pages d’un site web ou lorsque ce navigateur retourne
        ultérieurement sur un site web.
      </p>
      <p>
        Le site fait appel aux services Google Analytics qui utilisent un cookie
        pour comptabiliser les visiteurs et identifier la manière dont ils
        utilisent le site. Le cookie utilisé par Google Analytics sur ce site
        est paramétré de façon à anonymiser l’adresse IP de l’utilisateur (qui
        n’est jamais communiqué aux administrateurs de ce site). Le cookie
        installé par Google Analytics expire immédiatement, ce qui ne permet
        aucun tracking ultérieur de l’utilisateur (entre deux consultations du
        site par exemple).
      </p>
    </Wrapper>
  </Layout>
)

export default MentionsLegalesPage

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 2.25em 2em 1em 2em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 1200px) {
    margin: 0 12px 0;
  }
  @media (max-width: 678px) {
    padding: 1em 1em 0.5em;
    margin: 0;
  }
`
